module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139615876-2","anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alchymistův učeň","short_name":"Alchymistův učeň","start_url":"/","background_color":"#cfba9b","theme_color":"#3D2B1F","display":"minimal-ui","icon":"src/images/squared-circle.svg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
